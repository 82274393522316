define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "appDescription": "Hi I'm Joel. I make things. Contact me for any tailor-made software project you might have in mind.",
    "appName": "Developer of Things",
    "blog": {
      "index": {
        "hero": {
          "text": "Discover, integrate, and empower your applications with our APIs.",
          "title": "One API to rule them all"
        },
        "text": "Keep up with our latest updates, news and events.",
        "title": "Blog"
      }
    },
    "components": {
      "home": {
        "contact": "You can always check my <a href=\"https://www.linkedin.com/in/joelhcoliveira\" target=\"_blank\" rel=\"noopener\">LinkedIn</a> or drop me an <a href=\"mailto:me@joeloliveira.eu\">email</a>.",
        "title": "Hi I'm JOEL"
      }
    },
    "error": {
      "text": "We have encountered an unrecoverable error. This could be caused by recently deleted data, a bug in this application or server errors.<br><br>If the problem persists please contact our <a href=\"mailto:support@notifica.re\">support team</a>.",
      "title": "Error"
    },
    "fail": {
      "text": "We have encountered an unrecoverable error. It seems that the subdomain provided is not valid or configured.<br><br>If the problem persists please contact our <a href=\"mailto:support@notifica.re\">support team</a>.",
      "title": "Failed"
    },
    "home": {
      "title": "Joel Oliveira"
    },
    "restricted": {
      "text": "You do not have access to this page.",
      "title": "Restricted"
    },
    "unknown": {
      "text": "This page cannot be found or was recently removed",
      "title": "404"
    }
  }]];
});